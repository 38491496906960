import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout/index"
import cn from "classnames"
import Data from "../data/data"
import LOGO from "../images/logo-white.svg"
import { graphql } from 'gatsby';

import style from "./login.module.css"

export const query = graphql`
  query EnvQuery2 {
    site {
      siteMetadata {
        appServer,
        apiServer
      }
    }
  }
`;

class LoginPage extends React.Component {
  state = {
    email: "",
    password: "",
    loginError: false,
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value.trim(),
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { apiServer, appServer } = this.props.data.site.siteMetadata;
    const { password } = this.state;
    const email = this.state.email.toLowerCase();
    this.setState({ loginError: false });

    if (email && password) {
      const response = await fetch(`${apiServer}/api/users/login`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const json = await response.json();

      if (response.ok && json && json.id) {
        const home = json.broker ? '/search/opening' : '/fleet';
        document.location.href = `${appServer}${home}?token=${json.id}`;
      } else {
        this.setState({ loginError: true });
      }
    }
  }

  render() {
    const { email, password } = this.state
    return (
      <>
        <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{Data.login.title}</title>
            <meta
              name="description"
              content={Data.metaData.description.login}
            />
            <link
              href="https://fonts.googleapis.com/css?family=Raleway:700&display=swap"
              rel="stylesheet"
            />
          </Helmet>

          {/* Banner */}
          <div className={style.root}>
            <div className={style.login__banner}>
              <div className="container">
                <div className={`row ${style.customRow}`}>
                  <div className="col s12">
                    <div className={style.login__bannerContent}>
                      <h1 className={style.login__heading}>
                        <img className={cn(style.logo)} src={LOGO} alt="Logo" />
                      </h1>
                      <form
                        className={style.loginForm}
                        onSubmit={this.handleSubmit}
                      >
                        <input
                          type="text"
                          name="email"
                          value={email}
                          onChange={this.handleInputChange}
                          placeholder="Email"
                          className={cn(style.formInput)}
                        />
                        <input
                          type="password"
                          name="password"
                          value={password}
                          onChange={this.handleInputChange}
                          placeholder="Password"
                          className={cn(style.formInput)}
                        />
                        <div className={cn(style.form__loginError, { [style.active]: this.state.loginError })}>
                          Incorrect username or password. Please double-check and try again.
                        </div>
                        <div className={style.form__buttons}>
                          <button type="submit" className={style.formSubmit}>
                            Login
                          </button>
                          <div className={style.resetPw}>
                            <a className={style.link} href="/forgot">
                              Forgotten Password?
                            </a>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    )
  }
}

LoginPage.propTypes = {}

export default LoginPage
